.hamContainer,
#hamLabel {
  width: 100%;
  height: 100%;
  flex-direction: column;

}

.hamContainer{
  z-index: 100;
}

input[type="checkbox"] {
  display: none;
}

#hamLabel span:nth-child(1),
#hamLabel span:nth-child(2),
#hamLabel span:nth-child(3) {
  width: 70%;
  height: 0.4rem;
  background-color: #0c5563;
  box-shadow: 0.02rem 0.01rem 0.2rem rgb(255, 255, 255);
  margin: 2px 40px 2px 2px;
  border-radius: 20px;
  transition: 0.3s transform ease-in-out;
}

.hamOption {
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  color: white;
  /* background-color: rgba(0, 0, 0, 0.536); */
  background: radial-gradient(
    100% 3685.84% at 0% 6.44%,
    rgba(238, 252, 255, 0.98) 0%,
    #ace5f0 50.52%,
    #ebfcff 100%
  );
  display: none;
  opacity: 0;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden !important  ;
}

.hamOption ul {
  list-style: none;
  flex-direction: column;
  padding: 0;
}

.hamOption li {
  margin: 10%;
}
.hamOption ul li a {
  text-decoration: none;
}
#ham:checked ~ .hamOption {
  display: flex;
  opacity: 1;
  animation: fade 1s;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.navBtn {
  width: 250px;
  align-items: center;
  appearance: none;
  background-color: #fcfcfd;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.2) 0 2px 4px,
    rgba(45, 35, 66, 0.15) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #0c5563;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono", monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 25px;
  font-weight: bold;
}

.navBtn:focus {
  box-shadow: #d6d6e7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
}

.navBtn:hover {
  box-shadow: rgba(45, 35, 66, 0.3) 0 4px 8px,
    rgba(45, 35, 66, 0.2) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.navBtn:active {
  box-shadow: #d6d6e7 0 3px 7px inset;
  transform: translateY(2px);
}

#ham:checked ~ #hamLabel span:nth-child(1) {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

#ham:checked ~ #hamLabel span:nth-child(2),
#ham:checked ~ #hamLabel span:nth-child(3) {
  width: 8%;
  position: fixed;
  background-color: #0c5563;
  box-shadow: none;
  z-index: 1;
}

#ham:checked ~ #hamLabel span:nth-child(2) {
  transform: rotateZ(45deg);
}

#ham:checked ~ #hamLabel span:nth-child(3) {
  transform: rotateZ(-45deg);
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}