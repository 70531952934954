.navContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    /* z-index: 100; */
  }
  
  .navContainer {
    width: 100%;
    top: 0;
    /* position: absolute; */
    padding: 0.5% 5%;
    /* margin-bottom: 5%; */
    background: radial-gradient(
      100% 3685.84% at 0% 6.44%,
      rgba(238, 252, 255, 0.98) 0%,
      #ace5f0 50.52%,
      #ebfcff 100%
    );
    /* border-radius: 0 0 20px 20px; */
    box-shadow: 0 2px 10px 2px gray;
    z-index: 20;
  }
  .navLogo {
    /* width: %; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navLogo img {
    width: 60px;
  }
  
  .navTitles {
    text-decoration: none;
    color: #0c5563;
    text-shadow: 0.1rem 0.1rem 0.2rem #bcb4b4;
    width: 200px;
    font-family: "Zen Kurenaido", sans-serif;
    font-size: 1.4rem;
    font-weight: bolder;
    letter-spacing: 2px;
    padding: 5px;
    text-align: center;
    border-radius: 15px;
    cursor: pointer;
  }
  
  .navTitles:hover {
    transition: all 0.3s ease-in-out;
  }
  
  .navTitles:hover,
  .activeNav {
    color: rgb(255, 255, 255);
    background-color: #0c5563;
    box-shadow: 1px 2px 5px 1px rgb(161, 161, 161);
  }
  
  .hamBurger {
    display: none;
  }
  
  @media only screen and (max-width: 768px) {
    .navLogo {
      width: 100%;
      justify-content: flex-start;
      margin-left: 10px;
    }
  
    .navLogo img {
      width: 25%;
      border-radius: 0 0 0 10px;
    }
  
    .navTitles {
      display: none;
    }
  
    .hamBurger {
      display: flex;
    }
  }